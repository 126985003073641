

let createNavbarFunctionality = () =>{
  let openNav = () =>{
    let navElement = document.getElementById('navMenu')
    navElement.style.display = 'flex'
  }
  let closeNav = () =>{
    let navElement = document.getElementById('navMenu')
    navElement.style.display = ''
  }
  
  let openNavElement =   document.querySelector('#open-nav')
  let closeNavElement = document.querySelector('#close-nav')
  
  if(openNavElement && closeNavElement){
    openNavElement.addEventListener('click', openNav)
    closeNavElement.addEventListener('click', closeNav)
  }
}

export default createNavbarFunctionality