import imgUrl from '../../images/footer_logo.png'

const template = document.createElement('template');

template.innerHTML = `
<link rel="stylesheet" href="/web-components/footer/style.css">
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css">
<style>
.custom-footer-section {
  padding: 40px 30px;
  background-color: #052a47;
  color: #fff;
  bottom: 10px;
  /* border-bottom: 1px solid #bdbfc3;  */
}
.custom-container-regular {
  max-width: 1300px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0; /* Remove padding to bring elements closer to the edges */
  flex-wrap: wrap; /* Allow wrapping for smaller screens */
}
.custom-footer-left, .custom-footer-right {
  flex: 1;
  padding: 0; /* Remove padding to bring elements closer to the edges */
  margin: 0;
}
.custom-footer-left {
  display: flex;
  flex-direction: column;
  text-align: left;
}
.custom-footer-right {
  display: flex;
  flex-direction: column;
  text-align: right;
  margin-top: 60px; /* Adjust this value as needed to align the elements */
}

.footer-logo-wrap {
  background-image: url(${imgUrl});
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  width: 300px;
  height: 65px;
  margin-bottom: 20px;
}
.w-inline-block {
  max-width: 100%;
  display: inline-block;
}

.w-button {
  display: inline-block;
  padding: 9px 15px;
  background-color: #3898EC;
  color: white;
  border: 0;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
  border-radius: 0;
}
.footer-link {
  color: var(--white);
  text-transform: uppercase;
  padding-bottom: 10px;
  font-family: Revans, sans-serif;
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
  transition: color .2s;
  display: inline-block;
}
.footer-contact-link {
  color: var(--white);
  font-size: 16px;
  font-weight: 600;
  text-decoration: none;
  transition: color .2s;
  display: block;
}

.bold-text-5 {
  color: var(--white);
  font-weight: 500;
}
.primary-button {
  border: 1px solid var(--secondary-color);
  background-color: var(--primary-color);
  border-radius: 10px;
  padding: 18px 35px 17px;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.3;
  transition: color .2s, background-color .2s;
}
.footer-form-block {
  margin-top: 25px;
  margin-bottom: 0;
}
.custom-footer-logo-content-wrap, .custom-footer-newsletter-wrap, .custom-footer-contact-block {
  margin-bottom: 10px; /* Reduce margin-bottom to decrease space */
}
.custom-footer-newsletter-title {
  font-size: 24px; /* Adjust the font size as needed */
  font-weight: normal; /* Remove the bold styling */
  margin-bottom: 10px;
  color: #fff; /* Ensure the text color is white */
}
.custom-footer-newsletter-title, .custom-footer-newsletter-wrap p, .custom-footer-newsletter-wrap a, .custom-footer-contact-block p, .custom-footer-contact-block a {
  margin-bottom: 5px; /* Reduce margin-bottom to decrease space */
  color: #fff;
}
.custom-footer-links {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 10px; /* Reduce margin-top to decrease space */
}
.custom-footer-links a {
  color: #fff;
  text-decoration: none;
  font-size: 14px;
  margin-bottom: 5px; /* Reduce margin-bottom to decrease space */
  text-align: right;
}
.custom-social-icons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 10px; /* Reduce margin-top to decrease space */
}
.custom-social-icons a {
  text-decoration: none;
}
.custom-footer-widget-wrap .w-inline-block i {
  font-size: 30px;
  color: #536879;
  transition: transform 0.3s ease, color 0.3s ease;
}
/* Responsive Design */
@media (max-width: 768px) {
  .custom-container-regular {
      flex-direction: column;
      align-items: center;
  }
  .custom-footer-right {
      margin-top: 30px; /* Adjust this value as needed to align the elements */
      text-align: right;
  }
  .custom-footer-links {
      align-items: center;
  }
  .custom-footer-left, .custom-footer-right {
      text-align: center;
  }
  .custom-footer-right {
      margin-top: 20px;
  }
  .custom-social-icons {
      justify-content: center;
  }
}
  </style>
 <section class="custom-footer-section">
        <div class="custom-container-regular">
            <div class="custom-footer-left">
                <div class="custom-footer-logo-content-wrap">
                    <a href="index.html" aria-current="page" class="footer-logo-wrap w-inline-block w--current">
                        <img src=""  style="max-width: 100px;">
                    </a>
                </div>
                <div class="custom-footer-newsletter-wrap">
                    <h3 class="custom-footer-newsletter-title">Subscribe to our newsletter</h3>
                    <p class="paragraph-14">Click here for the email newsletter form.</p>
                    <div class="footer-form-block w-form">
                        <a href="https://share.hsforms.com/1_JvF_JOGSZ-kzKIbaT3MOwrwlli" class="primary-button w-button">Subscribe</a>
                    </div>
                </div>
                <div class="custom-footer-contact-block">
                    <div class="footer-contact-widget-wrap">
                        <p class="footer-contact-text">Any questions contact us at:</p>
                        <a href="mailto:hello@40hammocks.com" class="footer-contact-link">hello@40hammocks.com</a>
                    </div>
                </div>
            </div>
            <div class="custom-footer-right">
                <div class="footer-widget-wrapper">
                    <!-- <h3 class="footer-widget-title">Pages</h3> -->
                    <div class="custom-footer-links">
                        <a href="index.html" class="footer-link">Home</a>
                        <a href="featured-destinations.html" class="footer-link">FEATURED DESTINATIONS</a>
                        <a href="faq.html" class="footer-link">FAQ</a>
                        <a href="event-pro-program.html" class="footer-link">EVENT PRO PROGRAM</a>
                        <a href="privacy-policy.html" class="footer-link">PRIVACY POLICY</a>
                        <a href="terms-of-use.html" class="footer-link">TERMS OF USE</a>
                    </div>
                </div>
                <div class="footer-widget-block">
                    <div class="custom-footer-widget-wrap">
                        <p class="text-white">FOLLOW US ON</p>
                        <div class="custom-social-icons">
                            <a href="https://www.linkedin.com/company/40hammocks/" target="_blank" class="w-inline-block">
                                <i class="fab fa-linkedin"></i>
                            </a>
                            <a href="https://www.instagram.com/40hammocks/" target="_blank" class="w-inline-block">
                                <i class="fab fa-instagram"></i>
                            </a>
                            <a href="https://www.tiktok.com/@40hammocks" target="_blank" class="w-inline-block">
                                <i class="fab fa-tiktok"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
`

class Footer extends HTMLElement{
  constructor(){
    super();
    console.log('constructing?')
    this.attachShadow({mode: 'open'});
    this.shadowRoot.appendChild(template.content.cloneNode(true))
  }

}

window.customElements.define('custom-footer', Footer);
export default Footer;