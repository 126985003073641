import imgUrl from '../../images/web_header_logo.png'
const template = document.createElement('template');

template.innerHTML = `
<link rel="stylesheet" href="/web-components/nav-bar/style.css">
<style>
.nav-link-2 {
  color: #1a1b1f;
  letter-spacing: .25px;
  text-transform: uppercase;
  margin-left: 5px;
  margin-right: 5px;
  padding: 5px 10px;
  font-family: Montserrat, sans-serif;
  font-size: 13px;
  font-weight: 600;
  line-height: 20px;
  text-decoration: none;
}

.nav-menu-two {
  flex-flow: row;
  justify-content: center;
  align-items: center;
  display: flex;
}

.w-list-unstyled {
  padding-left: 0;
  list-style: none;
}

.nav-menu-wrapper {
  justify-content: center;
  align-items: flex-end;
  display: flex;

}
/* .w-nav-menu {
  position: relative;
  float: right;
} */

.navbar-logo-wrapper {
  flex-direction: row;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.container {
  /* moved */
  /* padding-top: 10px; */
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 100%;
  margin-left: 0;
  margin-right: 0;
  display: flex;
}

.navbar-logo-left-container {
  padding: 20px;
}

.navbar-brand{
  display: flex
}

#close-nav{
  display:none;
}

#open-nav{
  display:none;
}

@media screen and (max-width: 900px) {
  .nav-menu-wrapper{
      box-sizing: border-box;
      display: none;
      background: white;
      position: fixed;
      left: 0;
      top: 0;
      width: 100vw;
      height: 100vh;
      z-index:60;
      justify-content:space-between;
      align-items:flex-start;
      padding: 20px;
  }
  .nav-link-2{
    font-size: 17px;
  }

  .nav-menu-two{
  flex-flow: column;
  padding-top: 50px;
  gap: 40px;
  justify-content:baseline;
  align-items:baseline;
  margin: 0px;
  }

  #close-nav{
    display:inline;
  }

  #open-nav{
    display:inline;
  }

  .icon-button{
    border: none;
    background-color: rgba(0, 0, 0, 0);
    border-radius: 5px;
  }

  .icon-button:hover{
    background-color: rgba(0, 0, 0, 0.046);
  }

  .burger-slice{
    width: 30px;
    height: 3px;
    border-radius: 2px;
    background-color: rgb(62, 59, 59);
    margin: 7px 0;
  }

  .bar1{
    transform: translate(0, 10px) rotate(-45deg);
  }

  .bar2{
    opacity: 0;
  }

  .bar3{
    transform: translate(0, -10px) rotate(45deg);
  }
}
</style>
  <div class="navbar-logo-left">
    <div data-animation="default" data-collapse="medium" data-duration="400" data-easing="ease" data-easing2="ease" role="banner" class="navbar-logo-left-container shadow-three w-nav">
      <div class="container">
        <div class="navbar-logo-wrapper">
          <a id ='navLogoImg' href="index.html" aria-current="page" class="navbar-brand w-nav-brand w--current">
          <img src=${imgUrl}  width="232" alt=""></a>
        </div>
        <nav id = "navMenu" role="navigation" class="nav-menu-wrapper w-nav-menu">
          <ul role="list" class="nav-menu-two w-list-unstyled">
            <li>
              <a href="index.html" aria-current="page" class="nav-link-2 w--current"><strong class="bold-text-2">HOME</strong></a>
            </li>
            <li>
              <a href="featured-destinations.html" class="nav-link-2"><strong class="bold-text">FEATURED DESTINATIONS</strong></a>
            </li>
            <li>
              <a href="faq.html" class="nav-link-2"><strong class="bold-text-3">FAQ</strong></a>
            </li>
            <li>
              <a href="event-pro-program.html" class="nav-link-2"><strong class="bold-text-4">EVENT PRO PROGRAM</strong></a>
            </li>
          </ul>
          <button id="close-nav" class ="icon-button">
            <div class="burger-slice bar1"></div>          
            <div class="burger-slice bar2"></div>
            <div class="burger-slice bar3"></div>
          </button >
          <!-- <a href="rooms-search.html" class="available-button primary-button button-header w-button">Search for hotels</a> -->
        </nav>
        <button id="open-nav" class ="icon-button">
          <div class="burger-slice"></div>
          <div class="burger-slice"></div>
          <div class="burger-slice"></div>
        </button >
      </div>
    </div>
  </div>
`

class Navbar extends HTMLElement{
  constructor(){
    super();
    this.attachShadow({mode: 'open'});
    this.shadowRoot.appendChild(template.content.cloneNode(true))
  }

  openNav = () =>{
    let navElement = this.shadowRoot.getElementById('navMenu')
      navElement.style.display = 'flex'
  }
  closeNav = () =>{
    let navElement = this.shadowRoot.getElementById('navMenu')
      navElement.style.display = ''
  }
  connectedCallback(){

    this.shadowRoot.querySelector('#open-nav').addEventListener('click', this.openNav)
    this.shadowRoot.querySelector('#close-nav').addEventListener('click', this.closeNav)

  }
}

window.customElements.define('nav-bar', Navbar)
export default Navbar;